.banner {
    height: 260px;
    //border-bottom: 20px solid $color-primary;
    background-color: white;
     @media screen and (min-width: $small-width) {
        height: 350px;
    }
    @media screen and (min-width: $medium-width) {
        height: 750px;
    }
    button {
        top: -40px;
        position: relative;
        @media screen and (min-width: $small-width) {
            top: -80px;
        }
    }
    .image {
        background-image: url('/dist/images/back-valken.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        background-color: $color-primary;
        @extend %transition-slow; 
        overflow: hidden;
        
        &:before{
            content: "";
            display: block;
            width: 60%;
            height: 100%;
            background: #333;
            position: absolute;
            transform: skewX(35deg) translateX(-39%);
            left: 0;
            
        }
        //@media screen and (min-width: $medium-width) {
            @include flex();
            flex-wrap: nowrap;
        //}
        @media screen and (min-width: 1300px) {
            padding-left: 350px;
            &:before{
                width: 500px;
                transform: skewX(35deg) translateX(-265px);
            }
        }
        /*@media screen and (min-width: $medium-width) {
            background-position: 0 60px;
        }
        @media screen and (min-width: $medium-width) {
            background-position: 1920px 102px;
        }*/
        >*{
            position: relative;
            //top:0;
        }

        img {
            width: 100%;
            
            //min-width: 1000px;
            position: absolute;
            left: 0%;
            /* width: 106%; */
            //transform: translateX(-50%);
            bottom: 0;
            @media screen and (min-width: $medium-width) {
                //min-width: 1500px;
                min-width: 790px;
            }

            opacity: 1;
            
            /*@media screen and (min-width: $medium-width) {
                z-index: 2;
                opacity: 0;
                margin-top: 130px;
            } */
        }
    }
    &--subtext{
        background-color: $color-secondary;
        padding: 20px 0; 
        z-index: 3;
        position: relative;
        @media screen and (min-width: $small-width) {
            padding: 50px 0; 
        }
        h2 {
            color: white;
            font-size: 16px;
            text-align: center;
            
            @media screen and (min-width: $small-width) {
                font-size: 25px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 50px;
                line-height: 50px;
            }
        }
    }
    &--text{
        
        height: 150px;
        left: -21px;
        margin-top: -27px;
        background-position-x: right;
        align-self: center !important;
        @media screen and (max-width: 1600px) {
            left: 0;
            background-position-x: -700px;  
        }
        @media screen and (max-width: 1024px) {
            z-index: 1;
            transform: translate(0);
            align-self: flex-end  !important;
        }

        h1 {
          color: $white;
            font-size: 16px;
            text-align: left;
            font-weight: normal;
            text-transform: uppercase;
            margin-top: 36px;
            font-weight: bold;
            @media screen and (min-width: $small-width) {
                font-size: 25px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 57px;
            }
        }

        h2{
            font-weight: normal;
            font-size: 16px;
            text-align: left;
            @media screen and (min-width: $small-width) {
                font-size: 25px;
            }
             @media screen and (min-width: $medium-width) {
                font-size: 57px;
            }  
        }

    }

    &--foreground{
        height: 100%;
    }
} 