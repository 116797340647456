.page {
  padding-top: 200px;
  &-template-tpl-store-locator-php {
    padding-top: 0;
  }
       
    section:not(.tpl--store) {
        padding-bottom: 40px;
        a {
            color: $color-primary;
        }
        h2,.h2 {
            font-size: 40px;
            color: $color-primary; 
        }
        h3,.h3 {
            color: $color-secondary; 
            margin-bottom: 10px;
            font-size: 1.125em;
        }
       p, ul {
            color: $color-secondary;
            margin-bottom: 40px;
            font-family: $font-family--secondary;
       }
       ul {
        margin-left: 40px;
       }
    }
    &-warning__403,  &-warning__404{
      min-height: 600px;
      position: relative;
      .box--text {
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
      }
      h1 {
        font-size: 40px;
        color: $color-primary;
      }
      p {
        color: $color-secondary;
        font-size: 16px;
        font-family: $font-family--secondary;
      }
    }
  
}